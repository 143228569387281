.dash-bord-detail-wrapper {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 0 20px;

  .dashboard-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    margin: 0 10px;
    background: white;
    border-radius: 8px;
    padding: 2% 3%;
    box-shadow: 0 0 5px 0 rgba(43, 43, 43, 0.1),
      0 11px 6px -7px rgba(43, 43, 43, 0.1);
    .dashboard-detail-div {
      display: flex;
      align-items: center;
      h6 {
        margin-right: 20px;
        font-size: 18px;
        font-weight: 700;
        line-height: 19px;
        color: #333333;
      }
	  h3{
		width: 30px;
	  }
	  .detail-count{
		font-size: 24px;
		line-height: 29px;
		font-weight: 700;
        color: #333333;

	  }
    }
    .dashboard-icon {
      font-size: 30px;
      color: #f59f00;
    }
  }
}
