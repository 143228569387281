@import "../../assets/css/variable.scss";

.status-active {
  border: 1px solid green;
  background: rgba(51, 170, 51, 0.7);
  color: #fff;
  padding: 4px 10px;
  width: 100px;
  text-align: center;
  font-weight: 550;
  border-radius: 5px;
}
.status-deactiv {
  border: 1px solid red;
  background: rgb(216, 136, 136);
}

.status-pending {
  border: 1px solid orange;
  background: rgba(255, 165, 0, 0.7);
}
.filter-section {
  margin-bottom: 20px;
  .filter-div {
    margin: 0 10px;
    .demandType-filter {
      width: 100%;
      max-width: 150px;
      label {
        font-size: 16px;
        color: #484848;
        font-weight: 600;
      }
    }
  }
  .price-filter-label {
    font-size: 16px;
    color: #484848;
    font-weight: 600;
  }
  .price-filter {
    height: 36px;
    padding: 6px 12px;
    width: 100%;
    max-width: 120px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    &:focus-visible {
      outline: none;
    }
  }
  .postalCode-filter {
    max-width: 150px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .postalCode-filter[type="number"] {
    -moz-appearance: textfield;
  }
}
.search-filter-btn {
  margin-bottom: 20px;
  width: 125px;
  .mantine-Button-root {
    margin: 0 10px;
    width: 100%;
    // max-width: 125px;
    background-color: #f59f00;
    &:active {
      transform: translateY(0px);
    }
  }
}
.reset-filter-btn{
  width: 160px;
}
.object-detail-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.7);
  .object-detail-div {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
    transform: translate3d(0px, 0px, 0px);
    transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s,
      opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
    z-index: 10002;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    height: 90vh;
    max-height: 800px;
    // overflow: auto;
    .close-btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .close-btn {
        width: fit-content;
      }
    }

    .object-detail-section {
      height: 95%;
      .detail-section {
        height: 100%;
        overflow-y: scroll;
        margin: 0 20px;
        padding: 0 10px 0 0;
      }
    }
  }
}

.object-detail-table {
  .rdt_TableHeadRow {
    display: none;
  }
}
.external-link-icon {
  font-size: 20px;
  color: $yellow-sea;
}

.image-gallery-popup {
  border: 1px solid #edbe59;
}
.image-gallery {
  height: 400px;

  .image-gallery-content {
    height: 100%;

    .image-gallery-slide-wrapper {
      height: calc(100% - 100px);

      .image-gallery-icon {
        border: 1px solid lightgray;
        border-radius: 50%;
        filter: none;
        color: black;

        .image-gallery-left-nav .image-gallery-svg,
        .image-gallery-right-nav .image-gallery-svg {
          color: #a4a4a4;
        }

        &:hover {
          border: 1px solid lightgray !important;
          filter: drop-shadow(0 2px 2px #cececc) !important;

          .image-gallery-left-nav .image-gallery-svg,
          .image-gallery-right-nav .image-gallery-svg {
            color: $primary-color !important;
            height: 45px !important;
          }
        }
      }

      .image-gallery-swipe {
        height: 100%;

        .image-gallery-slides {
          height: 100%;

          .image-gallery-slide {
            height: 100%;
            overflow: hidden;

            div {
              height: 100%;
            }

            .image-gallery-image {
              height: 100%;
              width: auto;
              max-height: 675px;
              margin-top: 20px;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    padding: 0;
    margin: 50px 5%;
    .image-gallery-svg {
      height: 45px;
    }
  }

  .image-gallery-thumbnail {
    border-radius: 5px;
    overflow: hidden;
    border: 2px solid transparent;
    width: 115px !important;

    &:hover,
    &:focus,
    &.active {
      border: 2px solid $primary-color;
    }
    .image-gallery-thumbnail-image {
      width: 110px;
      height: 70px;
      object-fit: cover;
    }
  }
}

.object-gallery {
  width: 100%;
  height: 500px;
  .gallery-tittle {
    font-size: 22px;
    line-height: 34px;
    font-weight: 400;
    color: #374b6a;
  }
}

.mantine-Switch-track{
  cursor: pointer;
}