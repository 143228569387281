// @import url(../../assets/css/variable.scss);

.navbar-item {
  text-align: left;
  padding: 15px 25px;

  .navbar-item {
    padding: 15px;
    color: #374b6a;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      color: #a0acce;
    }
  }
  .active-tab {
    color: #f59f00;
  }
}

.user-icon {
  color: #f59f00;
  font-size: 34px;
  cursor: pointer;
}
