@import "../../assets/css/variable.scss";

.setting-section {
  .page-wrapper {
    padding: 10px 24px 10px 0px;
    transition: all 0.3s ease-in;
    .page-body {
      .card {
        border-radius: 5px;
        box-shadow: 0 0 5px 0 rgba(43, 43, 43, 0.1),
          0 11px 6px -7px rgba(43, 43, 43, 0.1);
        border: none;
        margin-bottom: 30px;
        transition: all 0.3s ease-in-out;
        .card-block {
          padding: 30px;
          .nav-tabs-wrapper {
            border-bottom: 1px solid #dee2e6;
            margin-bottom: 24px;
            .nav {
              display: flex;
              flex-wrap: wrap;
              padding-left: 0;
              margin-bottom: 0;
              list-style: none;
              border-bottom: 1px solid #dee2e6;
              .nav-item {
                font-size: 18px;
                color: #a4a4a4;
                border: none;
                padding: 0 10px 10px 15px;
              }
              .active-tab {
                border-bottom: 3px solid $yellow-sea;
                color: $yellow-sea;
              }
            }
          }
        }
      }
    }
  }
}

.email-tab-wrapper {
  .email-tab-tittle-div {
    margin-bottom: 30px;
  }
  .email-note {
    margin-bottom: 20px;
    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: #374b6a;
    }
  }
  .email-input {
    height: 36px;
    padding: 6px 12px;
    width: 100%;
    max-width: 350px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    &:focus-visible {
      outline: none;
      box-shadow: none;
    }
  }

  .error-border {
    border: 1px solid red;
  }
  .add-email-btn {
    margin: 0 30px;
    .mantine-Button-root {
      background-color: $yellow-sea;
      &:active {
        transform: translateY(0px);
      }
    }
  }
  .email-data-table-wrapper {
    width: 100%;
    max-width: 85%;
    .email-data-table {
      .rdt_Table {
        border: 1px solid #edbe59;
        .rdt_TableHead {
          border-bottom: 1.5px solid #edbe59;
        }
      }
    }
    .rdt_Pagination {
      border: none;
    }
  }
}

.edit-email-popup {
  .mantine-Modal-inner {
    .mantine-Paper-root {
      width: 700px;
      .mantine-Modal-body {
        text-align: center;
        .email-tab-wrapper {
          .email-input {
            width: 350px;
            max-width: unset;
          }
        }
      }
    }
  }
}
