.profile-info {
  background: white;
  width: fit-content;
  border-radius: 8px;
  padding: 2% 3%;
  margin-bottom: 30px;
  width: 80%;
  .admin-data {
    display: flex;
    flex-wrap: wrap;

    .admin-data-wrapper {
      width: 50%;
      font-weight: bold;

      // &:last-child,
      // &:nth-child(even) {
      // 	width: calc(50% - 50px);
      // 	margin-left: 50px;
      // }
      .admin-data-value {
        font-weight: normal;
      }
    }
    .user-status-div {
      .user-status-btn {
        cursor: pointer;
        background-color: #ffb000;
        border: none;
        border-radius: 6px;
        color: #fff;
        font-size: 15px;
        font-weight: 700;
        margin-left: 15px;
        max-width: 180px;
        min-height: 37px;
        width: 150px;
		&:focus-visible{
			outline: none;
		}
		&:focus{
			outline: none;
		}
      .admin-data-value {
        font-weight: normal;
      }
	}
      .bx {
        cursor: pointer;
      }
    }
  }
  .admin-data-icon {
    font-size: 56px;
    color: #f59f00;
  }
}

.changepassword {
  width: 80%;
}
