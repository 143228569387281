/**
* Template Name: WeBuild - v2.0.0
* Template URL: https://bootstrapmade.com/free-bootstrap-coming-soon-template-countdwon/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
@import "variable.scss";

:root {
  --app-primary-color: $primary-color;
  --app-secondary-color: rgba(55, 75, 106, 1);
}

body {
  font-family: $font !important;
  color: #444;
}

a {
  color: $primary-color;

  &:hover {
    text-decoration: none;
    color: $immozy-lightblue;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-primary {
  font-family: $font;
}



/*---------------------------------------------*/
.text--primary {
  color: $primary-color;
}

span.disable-links {
  pointer-events: none;
}

.main-section {
  min-height: calc(100vh - 100px);
  // padding-top: 80px;
  max-width: 1920px;
  margin: 0 auto;
}

.section-pd-top {
  padding-top: 40px;
}

.dropdown-toggle,
.dropdown-toggle:hover {
  color: $white-color;
}

.dropdown-item {
  padding: 10px;
}

.dropdown-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.dropdown-item:active,
.dropdown-item:focus {
  background-color: $secondary-color;
  color: $white-color !important;
}

.dropdown-item:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.dropdown-menu {
  padding: 0;
}

.dropdown-divider {
  margin: 0;
}


ul,
li {
  margin: 0px;
  list-style-type: none;
}

textarea {
  &:focus {
    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder {
      color: transparent;
    }
  }
}

input {
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: #999999;
  }
}

textarea {
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: #999999;
  }
}

label {
  display: block;
  margin: 0;
}
