.activation-icon {
  color: green;
  font-size: 18px;
  cursor: pointer;
  margin-right: 10px;
}
.deactiv-icon {
  color: red;
}
.pending-icon {
  color: orange;
}
.user-detail-icon {
  font-size: 18px;
  cursor: pointer;
}
.status-active {
  border: 1px solid green;
  background: rgba(51, 170, 51, 0.7);
  color: #fff;
  padding: 4px 10px;
  width: 100px;
  text-align: center;
  font-weight: 550;
  border-radius: 5px;
}
.status-deactiv {
  border: 1px solid red;
  background: rgb(216, 136, 136);
}

.status-pending {
  border: 1px solid orange;
  background: rgba(255, 165, 0, 0.7);
}

.back-icon-div {
  margin-right: 10px !important;

  .back-icon {
    font-size: 24px;
    color: white;
    line-height: 29px;
  }
}
.user-image {
  width: 100%;
  max-width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  font-size: 56px;
  color: #f59f00;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.userdetail-table {
  width: 80% !important;
}
.user-detail-wrapper {
  .rdt_TableHeader {
    width: 80% !important ;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  .rdt_TableHeadRow {
    border: none !important;
    margin-left: 10px;
  }
  .rdt_TableBody {
    // border: 1px solid #edbe59;
    width: 80% !important;
    margin-left: 20px;
  }
  .rdt_TableRow {
    border: none !important;
  }
  .rdt_TableHead {
    width: 80% !important;
  }
  .rdt_TableCell:first-child {
    border-left: 6px solid #edbe59 !important;
    border-bottom: 1px solid #edbe59;
    border-top: 1px solid #edbe59;
    border-right: 1px solid #edbe59;
  }
  .rdt_TableCell {
    border: 1px solid #ddd;
  }
}
.search-bar {
  display: flex;
  justify-content: flex-end;
  
  .search-bar-div {
    display: flex;
    align-items: center;
    position: relative;
    input {
      height: 36px;
      padding: 6px 0 6px 30px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      &:focus {
        outline: none;
      }
    }
    .seacrch-icon {
      position: absolute;
      left: 10px;
    }
  }
}
