@import "variable.scss";

:root {
  --app-primary-color: rgba(147, 40, 73, 1);
  --app-secondary-color: rgba(55, 75, 106, 1);
}

// $primary-color: rgba(147, 40, 73, 1);
// $secondary-color: rgba(55, 75, 106, 1);
// $immozy-dark: rgba(48, 56, 71, 1);
// $immozy-lightblue: rgba(160, 172, 206, 1);

body {
  font-family: $font;
  color: #444;
}

a {
  color: $primary-color;

  &:hover {
    text-decoration: none;
    color: $immozy-lightblue;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-primary {
  font-family: $font;
}

p{margin-bottom:0}
