.realtor-list-container {
  .realtor-data-label {
    width: 170px;
  }

  .input-row{
    width:40%;
    min-width: 400px;
  }
}
