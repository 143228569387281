@font-face {
  font-family: "BrandonText";
  font-display: auto;
  font-weight: 300;
  src: url("../fonts/BrandonText-Light.otf");
  font-display: swap;
}

@font-face {
  font-family: "BrandonText";
  font-display: auto;
  font-weight: 400;
  src: url("../fonts/BrandonText-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: "BrandonText";
  font-display: auto;
  font-weight: 700;
  src: url("../fonts/BrandonText-Bold.otf");
  font-display: swap;
}