* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  font-family: $font;
}

.page-title {
  background: rgb(55, 75, 106) !important;
  color: white !important;
  font-weight: 700;
  width: fit-content;
  border-radius: 8px;
  padding: 5px 25px;
  margin: 20px 0;
  height: 40px;
}

.login-container {
  width: fit-content;
  margin: 0 auto;
  border-radius: 14px;
  max-width: 1100px;
  -webkit-box-shadow: 4px 4px 27px -6px rgba(204, 204, 204, 1);
  -moz-box-shadow: 4px 4px 27px -6px rgba(204, 204, 204, 1);
  box-shadow: 4px 4px 27px -6px rgba(204, 204, 204, 1);
  background: #fff;
  min-height: 500px;
}

.login-img{
    border-bottom-left-radius: 14px;
    border-top-left-radius: 14px;
    width: 62%;
    height: 100%;
    object-fit: cover;
}

.main-container {
  background: linear-gradient(98deg, rgb(255, 255, 255) -1%, rgb(255, 241, 212) 100%);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-card {
  width: 40%;
  display: flex;
  justify-content: center;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: $full-height;
  font-family: $font;
}

.tips-description,
.filter-data-container {
  &::-webkit-scrollbar {
    width: 10px;
    height: 18px;
    background-color: $white-color;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    background-clip: padding-box;
    border-radius: 10px;
    background-color: $white-color;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    background-clip: padding-box;
    border-radius: 10px;
    background-color: #cecece;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

.text--primary {
  color: $primary-color;
}

.height-full {
  height: $full-height;
}

a {
  &:focus {
    outline: none !important;
  }

  &:hover {
    text-decoration: none;
  }
}




.pointer {
  cursor: pointer;
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  font-size: 14px;
  line-height: 1.7;
  margin: 0px;
}

.m--0-auto {
  margin: 0 auto !important;
}

.color-purple {
  background-color: $primary-color;
  color: $white-color;
}

input[type="checkbox"] {
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 6px;
  background-clip: content-box;
  border: 1.5px solid #bbbbbb;
  border-radius: 8px;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}


.object-fit-cover {
  object-fit: cover;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-t-5 {
  padding-top: 5px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-32 {
  margin-top: 32px;
}

.m-b-20 {
  margin-bottom: 20px;
}
.ml--15 {
  margin-left: 15px;
}
.mr--15 {
  margin-right: 15px;
}

.full-width {
  width: 100% !important;
}

.text-white {
  color: $white-color;
}

.text-black {
  color: $black-color;
}

.cursor-pointer {
  cursor: pointer;
}



.w--33 {
  width: 33%;
}
.w--50 {
  width: 50%;
}
.flex {
  display: flex;
}

.flex--column {
  flex-direction: column;
}

.flex--column-reverse {
  flex-direction: column-reverse;
}

.flex--row {
  flex-direction: row;
}

.flex--row-reverse {
  flex-direction: row-reverse;
}

.flex--wrap {
  flex-wrap: wrap;
}
.justify__content--center {
  justify-content: center;
}
.justify__content--end {
  justify-content: flex-end;
}

.justify__content--start {
  justify-content: flex-start;
}

.justify__content--between {
  justify-content: space-between;
}

.justify__content--around {
  justify-content: space-around;
}

.align__items--center {
  align-items: center;
}

.align__items--end {
  align-items: flex-end;
}

.align__items--start {
  align-items: flex-start;
}

.align__items--baseline {
  align-items: baseline;
}

.align__items--stretch {
  align-items: stretch;
}

.align__content--baseline {
  align-content: baseline;
}
.text--left {
  text-align: left;
}

.text--right {
  text-align: right !important;
}

.text--center {
  text-align: center;
}

.transform--180 {
  transform: rotate(180deg);
}

.d-none {
  display: none;
}
.d-block {
  display: block;
}

.position-relative {
  position: relative !important;
}

